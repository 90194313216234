import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultArticleBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_default_article_banner', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListArticleBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_article_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getArticleBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_article_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addArticleBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_article_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editArticleBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/edit_article_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteArticleBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/delete_article_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    articleBannerOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/article_banner_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageArticleBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/cms/upload_image_article_banner/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
